#home{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap-reverse;
    position: relative;
}
.home-text{
    width: 100%;
    max-width: 500px;
}
.home-text strong{
    font-weight:700;
    letter-spacing: 1px;
    font-size: 1.65em;
    margin-bottom: 8px;
}
.home-text h1{
    font-size: 3.5rem;
    line-height: 3.5rem;
    letter-spacing: 1px;
}
.home-text p {
    color: var(--grayColor);
    margin: 20px 0;
    letter-spacing: 1px;
    font-size: 1rem;
}
.home-img{
    min-width: 330px;
    width: 330px;
    height: 330px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.8;
    background: rgba(0, 0, 0, 0.2);
    position: relative;
    
}
.home-img::before{
    content: "";
    position: absolute;
    inset: -10px 140px;
    background: linear-gradient(315deg, #00ccff, #d400d4);
    transition: 0.4s;
    animation: animate 4s linear infinite;
}
.home-img:hover::before{
    inset: -20px 0;
}
@keyframes animate {
    0%{
        transform: rotate(0deg);
    }
    0%{
        transform: rotate(360deg);
    }
}
.home-img::after{
    content: " ";
    position: absolute;
    inset: 6px;
    background: rgb(22, 32, 82);
    border-radius: 50%;
    z-index: 1;
}
.img-box{
    position: absolute;
    inset: 30px;
    border: 6px solid rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.img-box img{
    z-index: 5;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: inline-block;
    object-position: top;
    transition: 0.5s;
    pointer-events: none;
}
.home-img:hover .img-box img{
    opacity: 0;
}

.home-img:hover .img-box h2{
    position: absolute;
    color: #fff;
    font-size: 1rem;
    text-align: center;
    font-weight: 600;
    letter-spacing: 0.05rem;
    z-index: 5;
    
}
.img-box .social a{
    color: #fff;
    padding: 0.5rem;
    

}
.img-box h2 span{
    font-weight: 300;
    font-size: 0.9rem;

}
.home-img:hover .img-box .social {
    top: 5rem;
    
    color: #fff;
    font-size: 1.5rem;
    margin: 0 7px;
    transition: 0.3s;
    z-index: 5;
    position: relative;
}
.img-box .social a :hover {
    text-shadow: 0 0 10px #fff;
    

}
.home-img:hover .img-box .hire-me{
    top: 4rem;
    position: relative;
    margin-top: 20px;
    padding: 6px 20px;
    background: #fff;
    color: #000;
    z-index: 5;
    font-weight: 500;
    border-radius: 25px;
    transition: 0.5s;
    font-size: 1.2rem;
    text-transform: uppercase;
}

@media (max-width: 865) {
    #home{
        justify-content: center;
        align-items: center;
    }
    .home-text{
        max-width: none;
    }
    
}


